import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const ExpandIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3326 3.33317C13.3326 3.15636 13.2624 2.98679 13.1374 2.86177C13.0124 2.73674 12.8428 2.6665 12.666 2.6665H9.33264C9.15583 2.6665 8.98626 2.73674 8.86124 2.86177C8.73621 2.98679 8.66597 3.15636 8.66597 3.33317C8.66597 3.50998 8.73621 3.67955 8.86124 3.80457C8.98626 3.9296 9.15583 3.99984 9.33264 3.99984H11.046L8.85931 6.19317C8.79682 6.25515 8.74723 6.32888 8.71338 6.41012C8.67954 6.49136 8.66211 6.5785 8.66211 6.6665C8.66211 6.75451 8.67954 6.84165 8.71338 6.92289C8.74723 7.00413 8.79682 7.07786 8.85931 7.13984C8.92128 7.20232 8.99502 7.25192 9.07626 7.28576C9.1575 7.31961 9.24463 7.33704 9.33264 7.33704C9.42065 7.33704 9.50779 7.31961 9.58903 7.28576C9.67027 7.25192 9.744 7.20232 9.80597 7.13984L11.9993 4.9465V6.6665C11.9993 6.84332 12.0695 7.01288 12.1946 7.13791C12.3196 7.26293 12.4892 7.33317 12.666 7.33317C12.8428 7.33317 13.0124 7.26293 13.1374 7.13791C13.2624 7.01288 13.3326 6.84332 13.3326 6.6665V3.33317Z"
        fill="currentColor"
      />
      <path
        d="M7.13935 8.8598C7.07737 8.79731 7.00364 8.74771 6.9224 8.71387C6.84116 8.68002 6.75402 8.6626 6.66602 8.6626C6.57801 8.6626 6.49087 8.68002 6.40963 8.71387C6.32839 8.74771 6.25466 8.79731 6.19268 8.8598L3.99935 11.0465V9.33313C3.99935 9.15632 3.92911 8.98675 3.80409 8.86173C3.67906 8.7367 3.50949 8.66646 3.33268 8.66646C3.15587 8.66646 2.9863 8.7367 2.86128 8.86173C2.73625 8.98675 2.66602 9.15632 2.66602 9.33313V12.6665C2.66602 12.8433 2.73625 13.0128 2.86128 13.1379C2.9863 13.2629 3.15587 13.3331 3.33268 13.3331H6.66602C6.84283 13.3331 7.0124 13.2629 7.13742 13.1379C7.26244 13.0128 7.33268 12.8433 7.33268 12.6665C7.33268 12.4897 7.26244 12.3201 7.13742 12.1951C7.0124 12.07 6.84283 11.9998 6.66602 11.9998H4.94602L7.13935 9.80646C7.20183 9.74449 7.25143 9.67075 7.28528 9.58951C7.31912 9.50827 7.33655 9.42114 7.33655 9.33313C7.33655 9.24512 7.31912 9.15798 7.28528 9.07675C7.25143 8.99551 7.20183 8.92177 7.13935 8.8598Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExpandIcon;
